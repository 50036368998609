@import "node_modules/bootstrap/scss/bootstrap";
@import "slick";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* main stylesheet */
body {
    font-family: 'Poppins', sans-serif;
}

.wrapper {
    width:100%;
}

.container {
    max-width: 1230px;
}

/* header start */
header {
    width:100%;
    background:#fff;
    color:#006699;
    font-family: 'Poppins', sans-serif;
    position:fixed;
    top:0;
    left:0;
    z-index: 999;

    @media (max-width:991px){
        height:65px;
        display: flex;
        align-items: center;
    }

    .container {
        max-width: 1350px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .logo {
        margin-right:auto;
        max-width: 182px;
        transition: all 0.4s ease-in-out 0s;

        @media (max-width:991px){
            max-width:140px;
        }
    }

    .main-nav-section {

        .hamburger-icon {
            @media (max-width:991px){
                width:40px;
                height: 40px;
                background: url('../images/hamburger-icon.png') no-repeat left top / 100%;
                display: inline-block;
                text-decoration: none;
            }

            &.close-icon {
                background: url('../images/close-icon.png') no-repeat left top / 100%;
            }
        }

        .main-nav {

            @media (max-width:991px){
                position:fixed;
                top:65px;
                right:-100%;
                background:#fff;
                height:calc(100% - 65px);
                width:100%;
                overflow: auto;
                visibility: hidden;
                transition: all 0.4s ease-in-out 0s;
            }

            &.show-nav {
                right:0;
                visibility: visible;
            }

            .first-level {
                width: auto;
                display: flex;

                @media (max-width:991px){
                    display: block;
                    padding:20px 0;
                }

                li {
                    padding:0 10px;
                    font-size:16px;
                    line-height:20px;
                    color:#006699;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    text-transform: uppercase;

                    @media (max-width:991px){
                        padding:10px 0;
                    }

                    a {
                        height:84px;
                        display: flex;
                        align-items: center;
                        position: relative;
                        text-decoration: none;
                        color:#006699;
                        transition: all 0.4s ease-in-out 0s;

                        @media (max-width:991px){
                            height: auto;
                            padding:10px 20px;
                        }
                    }

                    &.active, &:hover {
                        a{
                            &:after {
                                width:100%;
                                height:4px;
                                background:#43b6e0;
                                content:"";
                                position:absolute;
                                bottom:0;
                                left:0;

                                @media (max-width:991px){
                                    width:4px;
                                    height:100%;
                                }
                            }
                        }
                    }
                }
            }

        }

    }
}

/* footer start */
footer {
    width:100%;
    font-family: 'Poppins', sans-serif;
    background:#333333;
    color:#fff;
    padding:55px 0;

    @media (max-width:991px){
        padding:40px 0;
    }

    .footer-top {
        border-bottom:2px solid #4c4c4c;
        margin-bottom: 20px;
    }

    .copyright-txt {
        font-size:12px;
        line-height:16px;
        color:#ffffff;
        font-weight: 300;
        display: block;
        padding:0 0 10px;

        @media (max-width:991px){
            text-align: center;
        }
    }

    .social-links {
        display:flex;
        justify-content: flex-end;

        @media (max-width:991px){
            justify-content: center;
        }

        li {
            padding:0 18px 0 0;

            @media (max-width:991px){
                padding:0 10px;
            }

            &:last-child {
                padding-right:0;

                @media (max-width:991px){
                    padding-right:10px;
                }
            }

            a {
                text-decoration: none;
                display:block;
                background:url('../images/social-icon.png') no-repeat left top;

                &.linkedin-icon {
                    width:28px;
                    height:28px;
                    background-position: 0 0;
                }

                &.facebook-icon {
                    width:18px;
                    height:31px;
                    background-position: -46px 0;
                }

                &.twitter-icon {
                    width:29px;
                    height:31px;
                    background-position: -83px 0;
                }

                &.instagram-icon {
                    width:28px;
                    height:31px;
                    background-position: -131px 0;
                }

                &.youtube-icon {
                    width:32px;
                    height:31px;
                    background-position: -177px 0;;
                }
            }
        }
    }

    .privacy-links {
        display: flex;
        font-size:12px;
        line-height:16px;
        color:#ffffff;
        font-weight: 300;

        @media (max-width:991px){
            justify-content: center;
            margin-bottom: 20px;
        }

        li {
            margin-right:4px;

            a {
                color:#fff;
            }
        }
    }

    .logo-ft {
        max-width: 199px;
        transition: all 0.4s ease-in-out 0s;
        margin-bottom: 20px;
        display: inline-block;
    }

    .logo-ft-tagline {
        display:block;
        font-size:16px;
        line-height:24px;
        color:#ffffff;
        font-weight: 300;

        @media (max-width:991px){
            margin-bottom:30px;
        }
    }

    .footer-links {
        padding:0 0 30px;

        h4 {
            font-size:16px;
            line-height:20px;
            color:#ffffff;
            font-weight:500;
            text-transform: uppercase;
            padding:0 0 2px;
        }

        ul {
            li {
                font-size:12px;
                line-height:16px;
                color:#e2e2e2;
                font-weight: 300;
                padding:0 0 2px;

                a {
                    color:#e2e2e2;

                    &:hover {
                        color:#fff;
                    }
                }
            }
        }

        &.footer-links-st {
            ul {
                li {
                    font-size:16px;
                    line-height:20px;
                    font-weight: 500;
                    color:#ffffff;
                    text-transform: uppercase;

                    a {
                        color:#ffffff;
                    }
                }
            }
        }
    }
}

/* content start */
.content-body {
    font-family: 'Poppins', sans-serif;
    padding-top:84px;

    @media (max-width:991px){
        padding-top:65px;
    }
}

.section-title {
    font-size:32px;
    line-height: 1em;
    font-weight: 700;

    @media (max-width:767px){
        font-size:24px;
    }

    span {
        display: block;
        font-weight: 600;
        font-size:20px;

        @media (max-width:767px){
            font-size:16px;
        }
    }

}

.hero-section {
    width:100%;
}

.carousel {
    .hero-img {
        display:block;
    }

    h2 {
        line-height:42px;
        color:#ffffff;

        @media (max-width:991px){
            line-height:28px;
            padding-bottom: 10px;
        }
    }

    p {
        font-size:18px;
        line-height:32px;
        color:#ffffff;
        font-weight: 300;

        @media (max-width:991px){
            font-size:14px;
            line-height:18px;
        }
    }

    .carousel-caption {
        width:100%;
        top:0;
        bottom:auto;
        left:0;
        right:auto;
        height:100%;
        display: flex;
        align-items: center;
        text-align: left;

        @media (max-width:991px){
            position:static;
            background-color: #43b6e0;
        }

        &.text-align-left {
            .container {
                justify-content: flex-start;
            }
        }
        
        &.text-align-right {
            .container {
                justify-content: flex-end;
            }
        }

        &.text-align-bottom {
            height: auto;
            top:auto;
            bottom:0;

            .container {
                display:block;
            }

            .carousel-content {
                max-width:100%;
                padding:0;
                text-align: center;
                min-height: 70px;

                @media (max-width:991px){
                    min-height:10px;
                }
            }
        }

        .container {
            display: flex
        }

        .carousel-content {
            max-width: 500px;
            padding-right:7%;

            @media (max-width:991px){ 
                max-width: 100%;
                padding-right:0;
            }
        }
        
    }

    .carousel-control-prev, .carousel-control-next {
        width:42px;
        height:54px;
        top:50%;
        margin-top:-27px;
        text-decoration: none;
        opacity: 1;
        z-index: 99;

        @media (max-width:991px){
            width:24px;
            height:32px;
            margin-top:-16px;
        }
    }

    .carousel-control-prev {
        background:url('../images/arrow-icon-white.png') no-repeat left top / 100%;
        transform: rotate(180deg);
    }

    .carousel-control-next {
        background:url('../images/arrow-icon-white.png') no-repeat left top / 100%;
    }
}

.home-about-section {
    background:#f8f8f8;
    width:100%;
    padding:75px 0;

    @media (max-width:991px){
        padding:50px 0;
    }

    h3 {
        color:#006699;
        padding:0 0 20px;
    }

    p {
        font-size:16px;
        line-height:24px;
        color:#333333;
        font-weight: 300;
        text-align: justify;
        max-width: 320px;

        @media (max-width:991px) {
            max-width: 100%;
        }
    }

    
}

.about-num-bx {
    font-size:16px;
    line-height:20px;
    color:#000000;
    font-weight: 400;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    padding:0 20px;

    @media (min-width:768px) and (max-width:1200px) {
        padding:0 15px;
    }

    @media (max-width:767px) {
        text-align: center;
        font-size:14px;
        line-height: 18px;
    }

    &.blue-bg {
        background:#43b6e0;
        margin:8px 6px;
        border-radius:10px;
        color:#fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height:124px;
        padding:0 15px;
        text-align: center;

        * {
            color:#fff;
        }

        strong {
            font-weight: 500;
        }
    }

    strong {
        font-size:62px;
        line-height:1em;
        color:#43b6e0;
        font-weight: 600;
        display: block;

        @media (max-width:1200px) {
            font-size:48px;
        }

        @media (max-width:767px) {
            font-size:36px;
        }
    }
}

.our-offering-section {
    width:100%;
    padding:100px 0 80px;
    position: relative;

    @media (max-width:991px){
        padding:75px 0 60px;
    }

    h2 {
        line-height:36px;
        color:#006699;
        text-align: center;
        padding:0 0 50px;

        @media (max-width:767px){
            line-height: 1em;
        }
    }

    .offering-list {
        width:100%;
        float:left;
        display:block;
        text-align: center;

        li {
            display:inline-block;
            vertical-align: top;
            margin:0 8px 20px;
            width:164px;
            height:164px;
            background:#f8f8f8;
            border-radius:20px;
            padding:15px 12px;
            box-shadow: 0 7px 5px #bee7ec;
            transition: all 0.4s ease-in-out 0s;

            @media (max-width:767px){
                margin:0 5px 15px;
                width:152px;
            }

            &:hover {
                background:#fff;
            }
        }

        .offering-text {
            font-size:16px;
            line-height:18px;
            color:#333333;
            font-weight:400;

            @media (max-width:767px){
                font-size:14px;
            }
        }

        .icon-placeholder {
            display:block;
            height:85px;

            > * {
                display:block;
                margin:0 auto;
                position: relative;
            }
        }

        .security-icon {
            width:65px;
            height:64px;
            background:url('../images/security-icon.png') no-repeat left top / 100%;
            top:8px;
        }

        .consulting-icon {
            width:65px;
            height:65px;
            background:url('../images/consulting-icon.png') no-repeat left top / 100%;
            top:8px;
        }

        .migration-icon {
            width:70px;
            height:70px;
            background:url('../images/migration-icon.png') no-repeat left top / 100%;
            top:5px;
        }

        .managed-icon {
            width:65px;
            height:64px;
            background:url('../images/managed-icon.png') no-repeat left top / 100%;
            top:4px;
        }

        .billing-icon {
            width:68px;
            height:68px;
            background:url('../images/billing-icon.png') no-repeat left top / 100%;
            top:1px;
        }

        .automation-icon {
            width:78px;
            height:61px;
            background:url('../images/automation-icon.png') no-repeat left top / 100%;
            top:5px;
        }

        .content-delivery-icon {
            width:65px;
            height:65px;
            background:url('../images/content-icon.png') no-repeat left top / 100%;
            top:5px;
        }

        .disaster-icon {
            width:68px;
            height:68px;
            background:url('../images/disaster-icon.png') no-repeat left top / 100%;
            top:3px;
        }

        .backup-icon {
            width:66px;
            height:61px;
            background:url('../images/backup-icon.png') no-repeat left top / 100%;
            top:7px;
        }

        .big-data-icon {
            width:63px;
            height:63px;
            background:url('../images/big-data-icon.png') no-repeat left top / 100%;
            top:6px;
        }

        .ai-icon {
            width:66px;
            height:66px;
            background:url('../images/ai-icon.png') no-repeat left top / 100%;
            top:2px;
        }
    }
}

.texture-top-btm {
    position: relative;

    &:before {
        width:836px;
        height:424px;
        background:url('../images/offering-top-bg.png') no-repeat right top / 100%;
        content:"";
        position:absolute;
        top:0;
        right:0;

        @media (max-width:991px){
            width:100%;
        }
    }

    &:after {
        width:715px;
        height:579px;
        background:url('../images/offering-btm-bg.png') no-repeat left bottom / 100%;
        content:"";
        position:absolute;
        bottom:0;
        left:0;

        @media (max-width:991px){
            width:100%;
        }
    }

    .container {
        position: relative;
        z-index: 1;
    }
}

.our-experience-section {
    width:100%;
    padding:100px 0 75px;
    background:#006699;

    @media (max-width:991px){
        padding:75px 0 60px;
    }

    h2 {
        line-height:42px;
        color:#ffffff;
        padding:0 8% 75px;
        text-align: center;

        @media (max-width:991px){
            padding:0 0 60px;
        }

        @media (max-width:767px) {
            line-height: 32px;
        }
    }
}

.experience-item {
    width:300px;
    margin:0 auto;
    border-radius: 0 0 15px 0;
    overflow: hidden;

    &:hover {
        .experience-img {
            img {
                transform: scale(1.1);
            }
        }
    }

    .experience-img {
        display:block;

        img {
            transition:all 0.4s ease-in-out 0s
        }
    }

    .experience-caption {
        width:100%;
        
        a {
            display:block;
            height:70px;
            background:#43b6e0;
            border-radius: 0 0 15px 0;
            padding:10px 15px;
            position: relative;
            
            font-size:20px;
            line-height: 1em;
            color:#006699;
            font-weight: 600;
            text-decoration: none;

            &:after {
                width:26px;
                height:21px;
                background:url('../images/arrow-icon-blue.png') no-repeat left top / 100%;
                content:"";
                position:absolute;
                top:50%;
                right:15px;
                margin-top:-10px;
            }
        }
    }
}

.carousel-multiple-items {
    .slick-dots {
        padding:30px 0 0 0;
        display: flex;
        justify-content: center;

        li {
            padding:0 4px;

            button {
                width:12px;
                height:12px;
                background-color: #4d94b8;
                border-radius:50%;
                border:none;
                font-size:0;
            }

            &.slick-active {
                button {
                    background-color: #fff;
                }
            }
        }
    }
}

.proud-partner-section {
    width:100%;
    padding:80px 0;

    &.btm-bg {
        padding-bottom:40px;
    }

    h2 {
        color:#006699;
        text-align: center;
        padding:0 0 65px;
    }

    p {
        font-size:16px;
        line-height: 24px;
        color:#000000;

        @media (max-width:767px){
            max-width:100% !important;
        }
    }

    .mw-class {
        max-width: 86%;
        margin:0 auto;

        @media (max-width:991px){
            max-width:100%;
        }
    } 

    .card-bx {
        display:flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding:30px 0;

        .partner-logo {
            padding:0 25px;

            @media (max-width:767px){
                padding:0 15px;
            }

            img {
                @media (max-width:767px){
                    max-width:90%;
                }
            }
        }

        .box-heading {
            width:200px;
            height:auto;
            padding:10px 0;
            background:#43b6e0;
            border-radius:50px;
            display:flex;
            align-items: center;
            justify-content: center;
            position:absolute;
            top:-20px;
            left:50%;
            margin-left:-100px;
            text-align: center;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size:20px;
            color:#ffffff;
        }
    }
}

.card-bx {
    width:100%;
    max-width:458px;
    height:280px;
    border:1px solid #68ce00;
    border-radius:15px;
    background:#fff;
    box-shadow: 0 5px 5px #ebebeb;
    margin:0 auto;
    position: relative;

    &.gray-border {
        border:1px solid #a1a1a1;
    }

    &.ht-auto-mh {
        height:auto;
        min-height:160px;
        padding:30px 0 15px 0;
    }
}

.career-section {
    width:100%;
    height:425px;
    background:url('../images/career-bg.png') no-repeat center center / cover;
    display:flex;
    align-items: center;

    h3 {
        font-size:42px;
        line-height:1em;
        color:#ffffff;
        font-weight: 700;
        padding:0 0 10px;

        @media (max-width:767px) {
            font-size:28px;
        }
    }

    p {
        font-size:20px;
        line-height:1.2em;
        color:#ffffff;
        font-weight: 300;
        padding:0 0 10px;

        @media (max-width:767px) {
            font-size:16px;
        }
    }

    .cta-button {
        margin:0 15px;
        text-transform: uppercase;

        @media (max-width:767px) {
            margin:15px 0;
        }
    }
}

.cta-button {
    display:flex;
    align-items: center;
    justify-content: center;
    height:60px;
    min-width:230px;
    background:#fff;
    border-radius:5px;
    font-size:20px;
    font-weight: 600;
    color:#006699;
    text-decoration: none;
    transition: all 0.4s ease-in-out 0s;

    &:hover {
        background:#43b6e0;
        text-decoration: none;
        color:#fff;
    }
}

.success-stories-section {
    width:100%;
    padding:65px 0 50px;

    @media (max-width:991px){
        padding:45px 0;
    }

    .section-title {
        text-align: center;
        color:#006699;
        padding:0 0 45px;

        span {
            display: block;
            color:#333333;
            font-weight: 600;
            font-size:20px;
        }

        @media (max-width:991px){
            padding:0 0 30px;
        }
    }

    .success-gallery {
        .carousel {
            padding:0 75px;
            
            @media (max-width:991px){
                padding:0 35px;
            }
        }

        .carousel {
            p {
                color:#fff;
            }
        }
    }
}

.blog-section {
    padding:60px 0 100px;

    @media (max-width:991px){
        padding-bottom:75px;
    }

    &.head-black-col {
        .news-caption {
            h3 {
                a {
                    color:#000000;
                }
            }
        }
    }

    .section-title {
        text-align: center;
        color:#006699;
        padding:0 0 65px;

        @media (max-width:991px){
            padding:0 0 45px;
        }

        span {
            color:#000;
        }
    }

    .news-item {
        max-width:400px;
        padding:0 15px;
    }

    .news-img {
        padding:0 0 25px;
    }

    .news-caption {
        h3 {
            font-size:20px;
            line-height:22px;
            color:#43b6e0;
            font-weight: 600;
            padding:0 0 10px;

            a {
                color:#43b6e0;
            }
        }

        p {
            font-size:16px;
            line-height:24px;
            color:#333333;
            font-weight:300;
            padding:0 0 10px;
        }

        .read-more-link {
            font-size:16px;
            line-height:24px;
            color:#333333;
            font-weight:300;
            text-decoration: underline;
        }
    }

    .carousel-multiple-items .slick-dots li.slick-active button {
        background-color:#000;
    }
}

.share-section {
    width:100%;
    padding:45px 0;
    background:url('../images/contact-bg.png') no-repeat center center / cover;

    h3 {
        font-size:20px;
        line-height:26px;
        color:#ffffff;
        font-weight: 400;
        text-align: center;
        padding:0 0 30px;

        strong {
            font-weight: 600;
            display:block;
        }
    }
}

.textbox {
    width:100%;
    height:42px;
    border:1px solid #c1c1c1;
    border-radius:15px;
    background:#fff;
    outline:none;
    padding:0 15px;
    font-size:16px;
    color:#000000;
    font-weight:400;
}

.submit-button {
    width:222px;
    height:48px;
    background:#43b6e0;
    border-radius: 0 0 15px 0;
    margin:0 auto;
    font-weight: 600;
    color:#ffffff;
    font-size:20px;
    padding:0 15px;
    text-align: left;
    border:none;
    text-transform: uppercase;
    display: block;
    cursor: pointer;
    position: relative;
    transition: all 0.4s ease-in-out 0s;

    &:after {
        width:26px;
        height:21px;
        content:"";
        background:url('../images/arrow-icon-white2.png') no-repeat left top / 100%;
        position:absolute;
        top:50%;
        right:15px;
        margin-top:-10px;
    }

    &.cta-style {
        width: auto;
        max-width: 380px;
        display: flex;
        align-items: center;
        font-weight: 500;

        @media (max-width:767px){
            font-size:18px;
        }
    }

    &:hover {
        text-decoration: none;
        color:#fff;
        background:#006699;
    }
}

/* about */
.bg-img-style {
    background-repeat: no-repeat;
    background-position: right top;
    background-size:cover;
}

.page-banner-section {
    height:300px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width:767px){
        height:225px;
    }

    h1 {
        font-size:42px;
        line-height:1em;
        color:#ffffff;
        font-weight: 500;
        padding:40px 0;
        text-transform: uppercase;
        text-align: center;

        @media (max-width:767px){
            font-size:32px;
            padding:30px 0;
        }
    }

    .breadcrumb-section {
        font-size:14px;
        line-height:18px;
        color:#ffffff;
        font-weight: 400;
        text-align: center;

        a {
            color:#fff;
            text-decoration: none;
            margin-right:5px;

            &:hover {
                text-decoration: underline;
            }
        }

        span {
            margin-left:5px;
        }
    }
}

.overview-section {
    padding:75px 0 60px;

    @media (max-width:767px){
        padding:55px 0 45px;
    }

    h3 {
        font-size:20px;
        line-height:24px;
        color:#333333;
        font-weight:400;
        padding:0 0 15px;
        text-transform: uppercase;

        @media (max-width:767px){
            font-size:18px;
            line-height:22px;
        }
    }

    h2 {
        font-size:32px;
        line-height:42px;
        color:#006699;
        font-weight:500;
        padding:0 0 20px;

        @media (max-width:767px){
            font-size:24px;
            line-height:32px;
        }
    }

    .mw1 {
        max-width:670px;
    }

    p {
        font-size:16px;
        line-height:24px;
        color:#333333;
        font-weight: 400;
        padding:0 0 35px;

        strong {
            font-weight: 500;
        }

        &:last-child {
            padding-bottom:0;
        }
    }

    .d-reverse {
        display: flex;
        flex-direction: row-reverse;
    }
}

.our-section {
    width:100%;
    position: relative;
    padding:150px 0 100px;
    overflow: hidden;

    @media (max-width:991px){
        padding:100px 0 75px;
    }

    .mb-6 {
        @media (max-width:991px){
            margin-bottom: 6rem;
        }
    }

    .card-bx {
        padding:85px 30px 30px;
        text-align: center;
        height:300px;

        @media (max-width:767px){
            height: auto;
            padding:85px 15px 30px;
        }
    }

    .icon-circle {
        width:116px;
        height:116px;
        background:#006699;
        border-radius:50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position:absolute;
        top:-58px;
        left:50%;
        margin-left:-58px;

        .vision-icon {
            background-repeat: no-repeat;
            display: block;
            width:61px;
            height:43px;
        }

        .mission-icon {
            background-repeat: no-repeat;
            display: block;
            width:61px;
            height:61px;
        }
    }

    h4 {
        font-size:20px;
        line-height:24px;
        color:#006699;
        font-weight: 500;
        padding:0 0 20px;
        text-transform: uppercase;

        @media (max-width:1200px){
            font-size:18px;
            line-height:22px;
        }

        @media (max-width:767px){
            padding-bottom: 10px;
        }
    }

    p {
        font-size:24px;
        line-height:32px;
        color:#000000;
        font-weight:400;

        @media (max-width:1200px){
            font-size:20px;
            line-height:26px;
        }
    }
}

.gray-bg {
    background:#f6f6f6;
}

.our-competencies {
    background-color: #006699;
    overflow: hidden;
    position:relative;
    padding:45px 0 70px;

    &:before {
        width:275px;
        height: 609px;
        background: url('../images/semi-circle-texture.png') no-repeat left center / 100%;
        content:"";
        position:absolute;
        top:50%;
        left:0;
        transform: rotate(180deg);
        margin-top:-305px;
    }

    &:after {
        width:275px;
        height: 609px;
        background: url('../images/semi-circle-texture.png') no-repeat left center / 100%;
        content:"";
        position:absolute;
        top:50%;
        right:0;
        margin-top:-305px;
    }

    .container {
        position: relative;
        z-index: 1;
    }

    .section-title {
        color:#fff;
        padding-bottom: 50px;
    }

    .competencies-img {
        padding:0 15px;

        img {
            margin:0 auto;
        }
    }
}

.number-section {
    width:100%;
    background:url('../images/number-texture.png') no-repeat center top / cover;
    padding:50px 0;
}

/* career */

.opportunities-section {
    width:100%;
    background:url('../images/opportunities-bg.png') no-repeat center top / cover;
    padding:70px 0;   

    @media (max-width:767px){
        padding:50px 0;
    }

    p {
        font-size:24px;
        line-height:36px;
        color:#006699;
        font-weight:400;
        padding:0 0 35px;
    
        @media (max-width:767px){
            font-size:20px;
            line-height: 28px;
        }

        strong {
            font-weight:600;
        }
    }
}

.culture-section {
    .culture-bg {
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width:991px){
            height:300px;
        }
    }

    .culture-mw-ptb {
        max-width:565px;
        padding:100px 0 90px;

        @media (max-width:991px){
            max-width:100%;
            padding:50px 0;
        }
    }

    .section-title {
        color:#006699;
        padding:0 0 15px;
    }

    p {
        font-size:16px;
        line-height:24px;
        color:#333333;
        padding:0 0 15px;
    }
}

.bullet-item {
    width:100%;
    float:left;
    display:block;

    li {
        font-size:16px;
        line-height:24px;
        color:#000000;
        font-weight:400;
        padding:0 0 5px 25px;
        position:relative;

        &:before {
            width:15px;
            height:10px;
            background:url('../images/cloud-icon.png') no-repeat left top / 100%;
            content:"";
            position:absolute;
            top:6px;
            left:0;
        }
    }
}

.employee-section {
    width:100%;
    background:url('../images/employee-bg.png') no-repeat center center / 100% #006fa0;
    padding:60px 0 90px;

    @media (max-width:991px){
        padding:45px 0;
    }

    .section-title {
        color:#fff;
        text-align: center;
        padding:0 0 55px;

        @media (max-width:767px){
            padding:0 0 30px;
        }
    }

    .success-gallery {
        .carousel {
            padding:0 75px;
            
            @media (max-width:991px){
                padding:0 35px;
            }
        }

        .carousel {
            p {
                color:#fff;
            }
        }
    }
}

.core-values-start {
    width:100%;
    background: url('../images/core-value-texture-bg.png') no-repeat center center / cover;
    padding:100px 0 80px;

    @media (max-width:767px){
        padding:50px 0 30px;
    }

    .section-title {
        color:#006699;
        padding:0 0 20px;
    }

    h5 {
        font-size:20px;
        line-height:24px;
        color:#333333;
        padding:0 0 10px;
    }

    p {
        font-size:16px;
        line-height:20px;
        color:#000000;
        font-weight: 400;
    }

    strong {
        font-weight: 500;
    }
}

.green-color {
    color:#68ce00;
}

.blue-border-boxes {
    width:100%;
    max-width: 940px;
    margin:0 auto;
    text-align: left;

    @media ( max-width:991px){
        padding:0;
        max-width:738px;
    }

    @media ( max-width:767px){
        max-width: 345px;
    }

    .row {
        @media ( max-width:767px){
            margin:0;
        }
    }

    .row1-pad-btm {
        padding-bottom: 80px;

        @media ( max-width:767px){
            padding-bottom: 20px;
        }
    }

    span {
        font-size:16px;
        line-height:20px;
        color:#000000;
        font-weight: 400;

        @media (max-width:767px){
            font-size:10px;
            line-height:14px;
        }
    }

    .blue-border-main {
        display:inline-block;
        position: relative;

        @media (max-width:767px){
            margin-bottom:30px;
        }

        .blue-border-text {
            width:100%;
            height: 100%;
            position:absolute;
            top:0;
            left:0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding:0 20px;
            text-align: center;

            @media (max-width:767px){
                padding:0 10px;
            }

            &.white-col {
                span {
                    color:#fff;
                    line-height: 22px;
                }
            }

            span {
                font-size:18px;
                color:#006fa0;
                font-weight: 500;

                @media (max-width:767px){
                    font-size:12px;
                    line-height:14px;
                }
            }

            br {
                @media (max-width:767px){
                    display:none;
                }
            }
        }

        .blue-border-img {
            &.rotate-img {
                transform: rotate(180deg);
            }
            &.rotate-img-x {
                transform: rotateX(180deg);
            }
        }

        .blue-border-line1 {
            width:129px;
            height:25px;
            background:url('../images/blue-border-block-line1.png') no-repeat left top / 100%;
            display:block;
            position:absolute;
            top:50%;
            margin-top:-10px;

            @media (min-width:992px) and (max-width:1200px){
                width:110px;
            }

            &.left-pos {
                right:100%;
                transform: rotate(180deg);
                margin-top:-16px;

                @media ( max-width:991px){
                    background-size:auto;
                    background-position: right top;
                    width:80px;
                }

                @media ( max-width:767px){
                    width: 14px;
                    height: 10px;
                    background-size: 60px;
                    background-position: right;
                    margin-top:-6px;
                }
            }

            &.right-pos {
                left:100%;

                @media (min-width:992px) and (max-width:1200px){
                    margin-top:-8px;
                }

                @media ( max-width:991px){
                    background-size:auto;
                    background-position: right top;
                    width:80px;
                }

                @media ( max-width:767px){
                    width: 14px;
                    height: 10px;
                    background-size: 60px;
                    background-position: right;
                    margin-top:-4px;
                }
            }

            &.center-pos {
                transform: rotate(-90deg);
                top:-57px;
                left: 50%;
                margin-left: -62px;
                width: 106px;

                @media ( max-width:991px){
                    margin-left:-61px;
                }

                @media (max-width:767px){
                    width: 45px;
                    height: 10px;
                    top: -18px;
                    margin-left: -27px;
                }
            }
        }

        .blue-border-line2 {
            width:182px;
            height:99px;
            background:url('../images/blue-border-block-line2.png') no-repeat left bottom / 100%;
            display:block;
            position:absolute;
            bottom:100%;

            @media (max-width:767px){
                width:80px;
                height:46px;
            }

            &.blue-border-line3 {
                background:url('../images/blue-border-block-line3.png') no-repeat left bottom / 100%;

                &.right-pos {
                    margin-right:-19px;
                }
            }

            &.left-pos {
                left:0;
                transform: rotateY(180deg);
                margin-left:-44px;

                @media (min-width:992px) and (max-width:1200px){
                    margin-left:-45px;
                }

                @media (max-width:991px){
                    margin-left:-75px;
                }

                @media (max-width:767px){
                    margin-left:-26px;
                }
            }

            &.right-pos {
                right:0;
                margin-right:-18px;

                @media (min-width:992px) and (max-width:1200px){
                    margin-right:-18px;
                }

                @media (max-width:991px){
                    margin-right:-55px;
                }

                @media (max-width:767px){
                    margin-right:-15px;
                }
            }

            &.bottom-pos {
                top: 100%;
                bottom: auto;
                transform: rotateX(-180deg);

                &.rotate-z {
                    transform: rotateZ(-180deg);

                    @media (min-width:992px) and (max-width:1200px){
                        margin-left:-43px;
                    }
                }
            }
        }

        .blue-border-line4 {
            background:url('../images/blue-border-block-line4.png') no-repeat left top / 100%;
            width: 24px;
            height: 98px;
            display: block;
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -15px;
            margin-bottom: -2px;

            &.bottom-pos {
                transform: rotateX(180deg);
                top: 100%;
                bottom: auto;
                margin-bottom: 0;
                margin-left: -15px;
                margin-top: -3px;
            }
        } 
    }

    .mid-block {
        padding:110px 20px;
        color:#fff;

        @media (max-width:767px){ 
            padding:10px 20px 40px;
        }

        h4 {
            font-size:32px;
            line-height:36px;
            color:#ffffff;
            font-weight:500;

            @media (max-width:767px){
                font-size:24px;
                line-height:28px;
                padding:0 0 10px;
            }
        }

        p {
            font-size:16px;
            line-height:24px;
            color:#fff;
            font-weight: 400;

            @media (max-width:767px){
                line-height:18px;
            }
        }
    }

    .main-tagline {
        @media (max-width:767px){
            align-items:flex-start !important;
            padding-top:20px !important;
        }
    }

    .col-4 {
        @media (max-width:767px){
            padding-left:0 !important;
            padding-right:0 !important;
        }
    }
}

.perks-section {
    width:100%;
    background:url('../images/perks-bg.jpg') no-repeat center center / cover;
    padding:70px 0;

    @media (max-width:767px){
        padding:50px 0;
    }

    .section-title {
        color:#000;
        padding:0 0 15px;
    }

    p {
        font-size:16px;
        line-height:24px;
        color:#000000;
        font-weight: 400;
    }

    .box-mw {
        max-width:840px;
        margin:0 auto !important;

        .col-12 {
            padding-left:7px !important;
            padding-right:7px !important;
        }
    }

    .card-bx {
        height:110px;
        max-width: 100%;
        border-color: transparent;
        box-shadow: 0 5px 5px rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 14px;
        padding:10px 35px;

        &.ht-mx {
            height:140px;

            @media (max-width:767px){
                height: 110px;
            }
        }

        span {
            font-size:18px;
            line-height:24px;
            font-weight: 500;
            color:#000000;

            @media (max-width:767px){
                font-size:16px;
                line-height:20px;
            }
        }
    }
}

.opening-section {
    width:100%;
    background:#f6f6f6;
    padding:70px 0;

    @media (max-width:767px){
        padding:50px 0;
    }

    .section-title {
        color:#006699;
        padding:0 0 10px;
    }

    p {
        font-size:16px;
        line-height:24px;
        color:#000000;
        font-weight: 400;
    }

    .card-bx {
        height:auto;
        min-height: 180px;
        max-width: 100%;
        border-color: transparent;
        box-shadow: 0 3px 5px rgba(0,0,0,0.1);
        display: flex;
        text-align: left;
        flex-direction: column;
        margin-bottom: 30px;
        padding:25px 10px 25px 25px;

        .head-title {
            font-size:16px;
            line-height:20px;
            font-weight: 500;
            color:#000000;
            padding:0 0 10px;
        }

        .body-text {
            font-size:14px;
            line-height:24px;
            font-weight: 400;
            color:#000000;
        }
    }
}

.trusted-cloud {
    background:url('../images/trusted-texture-bg.png') no-repeat center center / cover #006699;
    padding:55px 0;

    @media (max-width:767px){ 
        padding:45px 0 25px;
    }

    .blue-border-boxes .blue-border-main .blue-border-text span {
        @media (max-width:991px){
            font-size:16px;
            line-height:18px;
        }
    }

    .blue-border-boxes .blue-border-main .blue-border-img,
    .blue-border-boxes .blue-border-main .blue-border-line2,
    .blue-border-boxes .blue-border-main .blue-border-line4 {
        @media (max-width:767px){
            display:none;
        }
    }

    .blue-border-boxes .blue-border-main .blue-border-text {
        @media (max-width:767px){
            border:1px solid #3caed9;
            border-radius:10px;
            position:static;
            padding:20px;
        }
    }
}

.timeline-section {
    padding:40px 0;

    .section-title {
        color:#006699;
        padding-bottom:40px;
    }

    /* timeline */

    .timeline {
        position: relative;
        max-width: 1200px;
        margin: 0 auto;
    }
    
    /* The actual timeline (the vertical ruler) */
    .timeline::after {
        content: "";
        position: absolute;
        width: 10px;
        background-color: #006699;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: -5px;
    }
    
    /* Container around content */
    .timeline-container {
        padding: 10px 0;
        position: relative;
        background-color: inherit;
        width: 50%;

        &.left {
            .year-badge {
                right:-40px;

                @media (max-width:767px){
                    left:-10px;
                    right:auto;
                }

                &:after {
                    right:100%;
                    margin-right:3px;

                    @media (max-width:767px){
                        right: auto;
                        left: 100%;
                        width:30px;
                    }
                }

                &:before {
                    @media (max-width:767px){
                        left:92px;
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &.right {
            .year-badge {
                left:-40px;

                @media (max-width:767px){
                    left:-10px;
                }

                &:after {
                    left:100%;
                    margin-left:3px;

                    @media (max-width:767px){
                        width:30px;
                    }
                }

                &:before {
                    left:142px;
                    transform: rotate(180deg);

                    @media (max-width:767px){
                        left:92px;
                    }
                }
            }
        }

        .year-badge {
            position: absolute;
            width: 80px;
            height: 80px;
            background-color: #43b6e0;
            border: 4px solid #fff;
            top: 25px;
            border-radius: 50%;
            z-index: 1;
            font-size:24px;
            line-height:26px;
            color:#ffffff;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;

            &:after {
                width:80px;
                height:1px;
                background-color: #bebebe;
                content:"";
                position:absolute;
                top:50%;
            }

            &:before {
                width:25px;
                height:19px;
                background:url('../images/timeline-content-circle.png') no-repeat left top / 100%;
                content:"";
                position:absolute;
                top:50%;
                left:-94px;
                margin-top:-10px;
                z-index: 1;
            }
        }
    }
    
    /* Place the container to the left */
    .left {
        left: 0;
        padding-right: 120px;
        text-align: right;
    }
    
    /* Place the container to the right */
    .right {
        left: 50%;
        padding-left: 120px;

        @media (max-width:767px){
            text-align: right;
        }

        .content {
            &:before, &:after {
                transform: rotate(180deg);
            }
        }
    }
    
    /* Add arrows to the right container (pointing left) */
    .right::before {
        content: " ";
        height: 0;
        position: absolute;
        top: 22px;
        width: 0;
        z-index: 1;
        left: 30px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
    }
    
    /* Fix the circle for containers on the right side */
    .right::after {
        left: -16px;
    }
    
    /* The actual content */
    .content {
        padding: 20px 40px;
        background-color: white;
        position: relative;
        border-radius: 20px;
        border: 1px solid #bcbcbc;
        display:inline-flex;
        align-items: center;
        text-align: center;
        max-width: 485px;
        min-height:110px;

        @media (max-width:991px){
            padding:20px;
        }

        @media (max-width:767px){
            display: flex;
            padding:10px 15px;
            justify-content: center;
        }

        &:before {
            width:25px;
            height:19px;
            background:url('../images/timeline-content-circle.png') no-repeat left top / 100%;
            content:"";
            position:absolute;
            top:0;
            left:40%;
            margin-top:-10px;
        }

        &:after {
            width:25px;
            height:19px;
            background:url('../images/timeline-content-circle.png') no-repeat left top / 100%;
            content:"";
            position:absolute;
            bottom:0;
            left:40%;
            margin-bottom:-10px;
        }

        p {
            font-size:20px;
            line-height:24px;
            color:#000000;
            font-weight: 400;

            @media (max-width:991px){
                font-size:16px;
                line-height:20px;
            }

            br {
                @media (max-width:767px){
                    display:none;
                }
            }
        }
    }
    
    /* Media queries - Responsive timeline on screens less than 600px wide */
    @media screen and (max-width: 767px) {
        /* Place the timelime to the left */
        .timeline::after {
            left: 31px;
        }
    
        /* Full-width containers */
        .timeline-container {
            width: 100%;
            padding-left: 100px;
            padding-right: 0;
        }
    
        /* Make sure that all arrows are pointing leftwards */
        .timeline-container::before {
            left: 60px;
            border: medium solid white;
            border-width: 10px 10px 10px 0;
            border-color: transparent white transparent transparent;
        }
    
        /* Make sure all circles are at the same spot */
        .left::after,
        .right::after {
            left: 15px;
        }
    
        /* Make all right containers behave like the left ones */
        .right {
            left: 0%;
        }
    }
    

}
